import { Controller } from 'stimulus';
import moment from 'moment';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import Rails from '@rails/ujs';
import 'datatables.net'; // Import DataTables

export default class extends Controller {
  static targets = ['reportDateRange', 'selectedDateRange', 'selectedDateField'];

  connect() {
    const { remote, filters } = this.element.dataset;

    if (remote === 'true') {
      this.remote = true;
      this.currentFilters = JSON.parse(filters);
    }

    this.dateRange = flatpickr(this.reportDateRangeTarget, {
      allowInput: true,
      mode: 'range',
      placeholder: 'Please select a date range',
      onChange: () => this.updatedDateRange(),
    });

    this.initializeDisplayedValues();

    // Initialize DataTables after the DOM is fully loaded
    this.initializeDataTable();

    // Restore the active tab state
    this.restoreActiveTab();
  }

  initializeDataTable() {
    $(document).ready(() => {
      $('#latest-quotes-table').DataTable({
        paging: false,
        searching: false,
        ordering: true,
        info: true,
      });
    });
  }

  // Save the active tab state to local storage
  saveActiveTab() {
    const activeTab = document.querySelector('.nav-link.active');
    if (activeTab) {
      localStorage.setItem('activeTab', activeTab.getAttribute('href'));
    }
  }

  // Restore the active tab state from local storage
  restoreActiveTab() {
    const activeTab = localStorage.getItem('activeTab');
    if (activeTab) {
      const tabElement = document.querySelector(`.nav-link[href="${activeTab}"]`);
      if (tabElement) {
        tabElement.classList.add('active');
        document.querySelector(activeTab).classList.add('show', 'active');
      }
    } else {
      // Set default tab if no active tab is stored
      const defaultTab = document.querySelector('.nav-link.default-tab');
      if (defaultTab) {
        defaultTab.classList.add('active');
        document.querySelector(defaultTab.getAttribute('href')).classList.add('show', 'active');
      }
    }
  }

  currentUrlSearchParams() {
    if (this.remote) {
      return new URLSearchParams(this.currentFilters);
    }

    return new URLSearchParams(window.location.search);
  }

  initializeDisplayedValues() {
    const urlParams = this.currentUrlSearchParams();

    const startDate = urlParams.get('start_date');
    const endDate = urlParams.get('end_date');
    const dateRange = urlParams.get('date_range');
    const dateField = urlParams.get('date_field');

    if (startDate && endDate) {
      this.selectedDateRangeTarget.textContent = `${moment(startDate).format('DD MMM YY')} - ${moment(endDate).format('DD MMM YY')}`;
    } else if (dateRange) {
      this.selectedDateRangeTarget.textContent = dateRange;
    } else {
      this.selectedDateRangeTarget.textContent = 'No date range selected';
    }

    if (dateField) {
      this.selectedDateFieldTarget.textContent = this.formatDateField(dateField);
      this.element.querySelector('select[name="date_field"]').value = dateField; // Set the select value
    } else {
      this.selectedDateFieldTarget.textContent = 'No date field selected';
    }
  }

  updatedDateRange() {
    const {
      selectedDates: [startDate, endDate],
    } = this.dateRange;
    if (!startDate || !endDate) {
      return;
    }

    const urlParams = this.currentUrlSearchParams();
    urlParams.set('start_date', moment(startDate).format());
    urlParams.set('end_date', moment(endDate).format());
    // Delete the currently set date range with the select picker
    urlParams.delete('date_range');

    // Display the results starting from the first page.
    urlParams.delete('page');

    this.applyURLParams(urlParams);

    // Update the displayed date range
    this.selectedDateRangeTarget.textContent = `${moment(startDate).format('DD MMM YY')} - ${moment(endDate).format('DD MMM YY')}`;
  }

  predefinedDateRangeSelected(e) {
    const dateRange = e.currentTarget.value;

    const urlParams = this.currentUrlSearchParams();
    // Delete the currently set dates with the calendar picker
    urlParams.delete('start_date');
    urlParams.delete('end_date');

    // Display the results starting from the first page.
    urlParams.delete('page');

    if (dateRange) {
      urlParams.set('date_range', dateRange);
    }

    this.applyURLParams(urlParams);

    // Update the displayed date range
    this.selectedDateRangeTarget.textContent = dateRange;
  }

  showFirmedBookingsToggled(event) {
    const { checked } = event.target;
    const urlParams = this.currentUrlSearchParams();
    urlParams.set('show_bookings', checked);

    // Display the results starting from the first page.
    urlParams.delete('page');

    this.applyURLParams(urlParams);
  }

  updatedDateField(event) {
    const dateField = event.currentTarget.value;

    const urlParams = this.currentUrlSearchParams();
    // Display the results starting from the first page.
    urlParams.delete('page');

    if (dateField) {
      urlParams.set('date_field', dateField);
    } else {
      urlParams.delete('date_field');
    }

    this.applyURLParams(urlParams);

    // Update the displayed date field
    this.selectedDateFieldTarget.textContent = this.formatDateField(dateField);
  }

  formatDateField(dateField) {
    if (dateField === 'hire_start') {
      return 'Hire Start';
    } else if (dateField === 'created_at') {
      return 'Created At';
    } else if (dateField === 'updated_at') {
      return 'Updated At';
    }
    return dateField;
  }

  applyURLParams(urlParams) {
    if (!this.remote) {
      window.location.search = urlParams.toString();
      return;
    }

    Rails.ajax({
      type: 'GET',
      url: `/agent/reports/quotes?${urlParams.toString()}`,
      dataType: 'script',
    });
  }
}

$(function () {
  $('[data-toggle="tooltip"]').tooltip();

  // Save the active tab state when a tab is clicked
  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    localStorage.setItem('activeTab', $(e.target).attr('href'));
  });
});