import { Controller } from 'stimulus';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import timeGridPlugin from '@fullcalendar/timegrid';
import multiMonthPlugin from '@fullcalendar/multimonth';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['container'];

  connect() {
    const eventItems = JSON.parse(this.data.get('event-items'));
    const filteredEventItems = eventItems.filter(event => {
      if (Array.isArray(event.className)) {
        return !event.className.includes('calendar-unapproved');
      }
      return event.className !== 'calendar-unapproved';
    });

    this.calendar = new Calendar(this.containerTarget, {
      plugins: [dayGridPlugin, listPlugin, bootstrapPlugin, timeGridPlugin, multiMonthPlugin],
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'multiMonthYear,dayGridMonth,listWeek',
      },
      initialDate: new Date(),
      navLinks: true,
      events: filteredEventItems,
      themeSystem: 'bootstrap',
      firstDay: 1,
      eventClick: (event) => {
        if (event.event.url) {
          window.open(event.event.url, '_blank');
        }
        event.jsEvent.preventDefault();
        return false;
      },
    });
    this.calendar.render();

    // A bit hacky, but the controller is rendered inside the tab content,
    // so we don't have a better way to access the tab selected/shown event.
    $('#dashboard-calendar-link.list-group-item').on('shown.bs.tab', () => {
      // Forcing the calendar to re-render avoids some glitches when switching
      // the selected tab + resizing the browser window.
      this.calendar.render();
    });
  }
}